.enlace_token_card_container {
    width: 100%;

    .header {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 48px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 0;

        img {
            width: 120px;
            padding-left: 1rem;
        }

        button {
            font-weight: bold;
            color: white;
        }
    }
}

.bloque-altaPrescriptor{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .altaPrescriptor {

        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 4rem;
        max-width: 1200px;
        padding: 4rem 2rem;

        .informacionReceta-altaPrescriptor{
            display: flex;
            flex-direction: column;
            background-color: white;
            flex: 1 1 600px;
            gap: 2rem;
            
            h2 {
                font-size: 1.5rem;
            }

            .item-informacionReceta {
                display: flex;
                flex-direction: row;
                gap: 2rem;
                align-items: center;
                font-size: 1.2rem;
                width: 100%;
                justify-content: space-between;
                min-height: 70px;

                p {
                    margin-bottom: 0;
                }
            }
            
            .logos {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
            
                .logoReceta {
                    width: 16rem;
                }
            
                .logoVidal {
                    width: 3rem;
                }
            
                .logoDelonia{
                    width: 3rem;
                }
            }
        }

        .formulario-altaPrescriptor {
        
            flex: 1 1 800px;
            max-width: 800px;
            
            .ant-form {
                background-color: #e2f1f8;
                padding: 2rem 2rem;
                
                .ant-input, .ant-select-selector {
                    border-color: white;
                    background-color: white !important;
                    border-radius: 0 !important;
                }
                .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
                    background-color: #fff;
                    border-color: #ff4d4f !important;
                }

                .ant-btn.ant-btn-primary.ant-btn-lg {
                    border-color: #057eac;
                    background-color: #057eac !important;
                    border-radius: 0;
                    font-size: 1.15rem;
                    height: 48px;
                }

                .altaPrescriptorForm.datosPersonales {
                    margin-top: 0;
                }
            }
        }
    }

    .bloqueVentajas-altaPrescriptor {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 750px;
        
        background-color: #057eac;
        width: 100%;

        display: flex;
        justify-content: center;

        .bloqueVentajas--inner {
            gap: 2rem;
            max-width: 1200px;
            display: flex;
            flex-direction: column;
            padding: 2rem 2rem 4rem 2rem;
            
            .bloqueVentajas-title{
                margin-bottom: 3.5rem;
            }
        }
        
        h2{
            color: white;
            font-size: 2rem;
        }

        .ventajas-bloqueVentajas{
            display: flex;
            flex-direction: row;
            color: white;
            gap: 2rem;
        }

        .item-ventajas{
            display: flex;
            flex-direction: row;
            gap: 2rem;

            p {
                margin-bottom: 1rem;
                font-weight: bold;
            }
        }

        .def-item-ventajas{
            display: flex;
            flex-direction: column;
        }
    }
}

@media (max-width: 1065px) { 
    
    .bloque-altaPrescriptor {
    
        .altaPrescriptor{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0rem;
            gap: 1rem;

            .informacionReceta-altaPrescriptor{
                gap: 1rem;
                margin-top: 1rem;
                flex-basis: auto;
                padding: 1rem 2rem;
            }

            .formulario-altaPrescriptor {
                flex-basis: auto;
            }
        }

        .bloqueVentajas-altaPrescriptor {
            
            position: relative;
            left: auto;
            top: auto;     
                
            margin-top: 4rem;

            .bloqueVentajas--inner {   
                gap: 0;

                flex-direction: column;
                align-items: center;

                .item-ventajas{
                    gap: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
    .alta-prescriptor-collapse-container {
        margin-top: 1rem !important;
    }
    .alta-prescriptor-video-container{
        height: 60vw !important;
        .alta-prescriptor-video-container-div{
            padding-left: 45vw !important;
        }

    }
    .alta-prescriptor-footer-div{
        .alta-prescriptor-footer-socialmedia{
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .alta-prescriptor-footer-icon{
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .alta-prescriptor-footer-texto{
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .alta-prescriptor-footer-socialmedia{
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
        .alta-prescriptor-footer-iconos-div{
            margin-right: 0 !important;
            margin-left: 0 !important;
     
        }
    
    }
    
}

@media (max-width: 850px){
    .alta-prescriptor-footer-container{
        .alta-prescriptor-footer-div{
            flex-direction: column !important;
            .alta-prescriptor-footer-socialmedia{
                margin-top: 1rem !important;
            }
    
            .alta-prescriptor-footer-icon{
                margin-top: 1rem !important;
            }
    
            .alta-prescriptor-footer-texto{
                margin-top: 2rem !important;
            }
    
            .alta-prescriptor-footer-socialmedia{
                margin-top: 1rem !important;
            }
            .alta-prescriptor-footer-iconos-div{
                margin-top: 2rem !important;
                margin-bottom: 1rem !important;     
                :last-child{
                    margin-right: 0 !important;
                }
                
            }
            
        }
        
    }
    .contact-form .ant-row {
        margin-right: 0 !important;
    }
    .contact-form-row {
        flex-direction: column;
        align-items: center;
        .second-row {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0.25rem !important;
        }

        .contact-submit-btn {
            width: 100%;
            max-width: none !important;
        }
    }
}


@media (max-width: 700px) { 

    .alta-prescriptor-video-container{
        height: 80vw !important;
    }

    .bloque-altaPrescriptor {

        .altaPrescriptor {

            margin-top: 0rem;

            .bloqueVentajas--inner {

                .ventajas-bloqueVentajas {
                    display: flex;
                    flex-direction: column;
                }

                .item-bloqueVentajas{
                    gap: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .def-item-ventajas{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}


.alta-prescriptor-collapse-container {
    margin-top: 13rem;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    width: 90%;
    margin-bottom: 4rem;
    max-width: 70rem;

    .alta-prescriptor-collapse-title{
        margin-bottom: 2rem;
    }

    .ant-collapse{
        font-size: large;
        width: 100% !important;
        .ant-collapse-item{
            margin-bottom: 0.5rem;
            border-bottom: 0;
            .ant-collapse-header{
                padding: 0;
            }
            
        }
    }

    

    .anticon{
        svg{
            width: 1.3rem;
            color: #d80045;
            height: 1.3rem;
        }
    }
    
}
.alta-prescriptor-collapse-header {
    display: flex;
    align-items: center;
    border-bottom: solid 1px grey;
    padding-right: 4rem;
    width: 100%;
}


.alta-prescriptor-collapse-header-icon{
    min-width: 3.5rem;
    margin-right: 1rem;
    height: 3.5rem;
    width: 3.5rem;
    background-color: #00c6ed;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-inline--fa{
        width: 35px;
        height: 35px;
    }
}

.alta-prescriptor-collapse-header-text{
    font-weight: 600;
}

.alta-prescriptor-collapse-header .span{

        margin-left: 10px;
        font-size: 16px;

    
}
.alta-prescriptor-collapse-inner-text{
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 2rem;
    &:first-child{
        padding-top: 2rem;
    }
    &.par{
        background-color: #f9f9f9;
    }
    &.impar{
        background-color: white;
    }
}
.alta-prescriptor-video-container{
    width: 100%;
    height: 38rem;
    background: url('../images/imgLogin.jpg') no-repeat;
    background-size: cover;
    padding-right: 20rem;

    .alta-prescriptor-video-container-div{
        padding-left: 40vw;
        display: flex;
        flex-direction: column;
        width: fit-content;
        position: relative;
        overflow: hidden;
        width: fit-content;
        min-width: 50%;
        .alta-prescriptor-video-container-title{
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
    .alta-prescriptor-video-iframe{
        width: 50vw;
        max-width: 850px;
        max-height: 450px;
        height: 40vw;
        border: 1rem white solid;
    }
}
.alta-prescriptor-footer-container{
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .alta-prescriptor-footer-div{
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: fit-content;

        .alta-prescriptor-footer-icon{
            display: flex;
            margin-right: 12vw;
            margin-left: 2rem;
            
            img{
                width: 200px;
            }
        }
        .alta-prescriptor-footer-texto{
            display: flex;
            flex-direction: column;
            color: #00c6ed;
            align-items: center;
            margin-right: 2vw;
            margin-left: 2vw;
            span{
                white-space: nowrap;
            }
        }

        .alta-prescriptor-footer-socialmedia{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #00c6ed;
            margin-right: 2vw;
            margin-left: 2vw;
            div{
                white-space: nowrap;
            }
            img {
                
                cursor: pointer;
                border-radius: 10%;
                background-color: white;
                margin-top: 1rem;
                width: 20px;
                height: 20px;
            }
        }
        .alta-prescriptor-footer-iconos-div{
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            margin-left: 2vw;
            :first-child{
                margin-right: 1rem;
            }
            :last-child{
                margin-right: 4rem;
            }
            img {
                width: 60px;
                height: 70px;
               
            }
        }
    }
}

.alta-prescriptor-contacto-container {
    padding: 2rem;
    background-color: #fff;
    margin: 0;
    width: 90%;
    max-width: 70rem;

    .intro-text {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    .contact-details {
        margin-left: 1rem;
        margin-bottom: 2rem;
        border-left: 2px solid #057eac;
        padding-left: 1rem;

        .contact-item {
            font-size: 1rem;
            margin-bottom: 0.5rem;

            span {
                font-weight: normal;
                margin-right: 0.5rem;
            }
        }
    }


    .contact-form-title {
        margin-left: 1rem;
        font-size: 1rem;
        margin-bottom: 1.5rem;
        border-left: 2px solid #057eac;
        padding-left: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }


    .contact-form {
        display: flex;
        flex-direction: column;
        .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
            background-color: #fff;
            border-color: #ff4d4f !important;
        }

        .ant-row{
            width: 100%;
            margin-right: 0.25rem;
            margin-bottom: 0;
        }

        .ant-form-item-explain{
            display: none ;
        }

        .contact-input {
            padding: 0.8rem;
            margin-bottom: 0.25rem;
            color: black !important;
            border-radius: 4px !important;
            font-size: 1rem;
            background-color: #e2f1f8 !important;
            text-overflow: ellipsis;
            border-color: #e2f1f8;

            &.first-row {
                width: 100%;
                border-radius: 0 !important;
            }

            &.second-row {
                border-radius: 0 !important;
                margin-right: 0.25rem;
                width: 100%; 
                
            }
        }

        .contact-form-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            .contact-submit-btn {
                border-radius: 0;
                max-width: 7rem;
                background-color: #e10846;
                color: white;
                border: none;
                padding: 0.76rem;
                cursor: pointer;
                width: 100%;  
                height: 100%;
                font-weight: 500;
                font-size: large;
            }
        }
    }

}


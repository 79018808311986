@import 'variables.scss';

#root {
    height: 100%;
}

.main-layout {
    height: calc(100vh - 64px);
    padding: 0 1rem;
    position: relative;
    overflow: hidden;
}

.filters-drawer {
    position: absolute;

    z-index: 999;
    
    .ant-drawer-body { 
        border-left: 2px solid gainsboro;
        background-color: whitesmoke;
        padding: 16px 12px;
    }

    .ant-drawer-mask {
        background-color: transparent;
    }
}

/** ANTD OVERRIDE **/
.ant-spin-container {
    transition-duration: 1s;
}

.ant-select-selection-search-input input::placeholder {
    font-style: italic;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-color: lightgray;
}

.configuration-drawer {

    input {
        text-align: right;
    }

    .ant-drawer-title .anticon {
        margin-right: 0.5rem;
    }

    .ant-drawer-body {
        padding: 0.5rem 1rem;
    }
}

.ant-tabs-tab {

    margin: 0 8px 0 0;
    padding: 12px;

    .tab-title {

        position: relative;
    
        span {
            position: absolute;
            right: -28px;
            top: -12px;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        }
    }
    
    &:hover {
        .tab-title span {
            opacity: 1;
            transform: translateY(6px);
        }
    }
}

.ant-form-large .ant-form-item-label > label {
    height: auto;
}

.ant-form-item-has-error .ant-radio-group {
    border: 1px solid red;

    .ant-radio-button-wrapper {
        border-top-width: 0;
        border-bottom-width: 0;
    }

    .ant-radio-button-wrapper:first-child {
        border-left-width: 0;
    }

    .ant-radio-button-wrapper:last-child {
        border-right-width: 0;
    }
}

.radio-group--expanded.ant-radio-group {
    width: 100%;
    display: flex;
    
    .ant-radio-button-wrapper {
        flex: 1 1 auto;
    }
}

.select-tag--centered {

    .ant-select-selection-item-content {
        display: flex;
        align-items: center;
    }

    .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
    }
}

/** NEW CHANGES **/
.ant-layout {
    background: white;
}

.ant-table {
    color: $main-color !important;

    .ant-table-thead > tr > th {
        font-weight: bold;
        border-bottom: 0;
    }

    .ant-table-tbody > tr  {
        
        &:nth-child(2n) {
            background-color: #FAFAFA;
        }

        & > td {
            border-bottom: 0;
        }
    }

    .ant-table-tbody > tr.ant-table-measure-row ~ tr  {

        &:nth-child(2n) {
            background-color: white;
        }

        &:nth-child(2n + 1) {
            background-color: #FAFAFA;
        }
    }
}

.ant-btn {

    font-size: 0.85rem;

    &:not(.ant-btn-circle) {
        border-radius: 4px;
    }

    &:not(.ant-btn-dangerous):not(.ant-btn-link):not([disabled]) {
        border: 1px solid #1890ff;

        &:not(.ant-btn-primary):not([disabled]) .anticon {
            color: #1890ff;
        }
    }
}

.ant-upload-list-item {
    .ant-btn:not(.ant-btn-dangerous):not(.ant-btn-link) {
        border: none !important;
    }
}

/** Overriden default input styles **/
.ant-input-affix-wrapper, .ant-input, .ant-picker, .ant-select .ant-select-selector {
    border-radius: 4px !important;
    background-color: #F8F8F8 !important;

    box-shadow: none !important;
}

.ant-input-affix-wrapper .ant-input {
    border-radius: 0 !important;
}

.ant-layout-sider {

    .ant-input, .ant-picker, .ant-select .ant-select-selector,
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background-color: white !important;
    }
}


input:not(:hover):not(:focus):not([disabled]),
.ant-select:not(.ant-select-focused):not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector:not(:hover) {
    //border-color: transparent !important;
}

.ant-select-show-search {
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        left: 0;
    }

    input {
        border-radius: 0 !important;
    }
}

/** Overriden disabled input styles **/
.form-detail-container {

    .ant-form-item-label {
        text-align: left;
        font-weight: bold;

        label::after {
            display: none;
        }
    }

    .ant-input[disabled], .ant-picker.ant-picker-disabled, .ant-select-disabled .ant-select-selector,
    .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
        cursor: inherit !important;
        border: 0 !important;
        background-color: inherit !important;
        border-bottom: 1px solid gray !important;
        color: gray !important;
        padding-left: 0 !important;
        border-radius: 0 !important;
    }

    .ant-picker-input > input[disabled] {
        color: gray !important;
        cursor: inherit !important;

        & + .ant-picker-suffix {
            display: none;
        }
    }

    .ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
        display: none;
    }

    .ant-select-disabled .ant-select-arrow {
        display: none;
    }
}

.ant-input-group {

    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-radius:  0 !important;
        border: 1px solid #d9d9d9;
        border-left: 1px solid transparent !important;
        border-right: 1px solid transparent !important;
    }

    .ant-input-group-addon {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        overflow: hidden;
    }

    .ant-input + .ant-input-group-addon {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: hidden;
    }

    .ant-input {
        border-radius: 4px !important;
    }

    .ant-input-group-addon + .ant-input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.ant-input-with-addon-after {

    .ant-input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

/** CUSTOM **/
.app-loading-mask {
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, 0.5);
    color: $icon-color;
    align-items: center;
    justify-content: center;
    
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.icon-color {
    color: $icon-color
}

.tabpanel-height-100 {
    height: 100%;

    .ant-tabs-content {
        height: 100%;
    }
}

.ant-layout-sider-trigger {
    background-color: #414756;
}

.input-right-align {
    text-align: right;

    input {
        text-align: right !important;
    }
}

.ant-modal-body {
    
    .ant-result {
        padding: 0;
    }

    .ant-result-title {
        line-height: normal;
    }

    .ant-result-subtitle {
        color: dimgray;
    }

    .ant-result-icon > .anticon {
        font-size: 56px;
    }
}

.view-policy-link{
    display: inline;
    color: $icon-color;
    cursor: pointer;
}
.privacy-policy-modal{
    margin: 1rem;
    .title-policy-modal{
        font-size: 2rem;
        font-weight: bold;
    }
    .section-policy-modal{
        border-top: 0.5px solid lightgray;
        h1{
    
            margin-top: 1rem;
            font-size: 1.5rem;
            font-weight: bold;
           }
    }

    h2{
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: bold;
        text-decoration-line: underline;
    }

    ul{
        li{
            margin-bottom: 0.2rem;
            :last-child{
                margin:0
            }

        }

    }

    div{
    margin-bottom: 1rem;

    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}

.ant-table_red {
    color: #e10846 !important;
}
.tableNameDiv{
  text-overflow: ellipsis;
  overflow:hidden;
  white-space: nowrap;
}
.spanSelectDCPFButton{
    padding-left: 0.2rem;
}
.radioGroupTypeSearch{
    margin-bottom: 1rem;
}

.selectMedicineNameDiv{
    display: flex;
    align-items: center;

    .productoSearchIcon, .circleFlag {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 1rem;
        margin-left: 0.5rem;
        color: gray;
        font-size: smaller;
        height: 1rem;
        width: 1rem;
        overflow: hidden;
        svg {
            color: rgb(210, 210, 210);
        }
        &.visado{
            background-color: gold;
        }
        &.financiado{
            color:white;
            background-color: #1890FF;
        }

        &.psumInfo.activo {
            cursor: pointer;
            svg {
                color: deeppink !important;
            }
        }

        .favorito {
            svg{
                color: rgb(244, 162, 40);
            }
        }

        .anterior {
            color: coral;
        }

        &.psico.activo {
            svg {
                color: #E1A800;
            }
        }

        &.normal{
            img{
                margin-left: 0rem !important;
            }
        }
        &.bn{
            img{
                margin-left: 0rem !important;
            }
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            opacity: 0.5;
        }
    }

    .circleFlag {
        border-radius: 50%;
        background-color: rgb(210, 210, 210);
    }
   
    img{
        width: 100%;
        height: 100%
    }

}
.button__visado{
    background-color: gold;
    font-weight: bold;
    border-color: black !important;  
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    flex-direction: row;
    align-content: space-around;
    align-items: center;
    justify-content: space-around;
}
.uno {
    flex-direction: row;
}

.item{
    margin-bottom: 1rem;
    margin-top: -0.5rem;
}

.infoSalud {
    border-radius: 0.5rem;
    margin: 1rem;
    
    padding: 1rem;
    min-width: 40rem;
}
.infoPatologia {
    border-radius: 0.5rem;
    margin: 1rem;
    height: auto;
    padding: 1rem;
}

.infoPatologia {

    border-radius: 0.5rem;
    margin: 1rem;
    height: auto;
    padding: 1rem;
}

.infoTratamientosActivos {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 1rem;
    margin-top: -26rem;
    height: auto;
}

.infoTratamientosNoActivos {
    display: flex;
    flex-direction: column;
    background-color: '#FFECEC';
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 1rem;
    height: auto;
}

.infoSalud2 {
    display: flex;
    flex-direction: column;
    position:relative;
    border-radius: 0.5rem;
    margin: 1rem;
    height: 25rem;
    padding: 1rem;
    min-width: 40rem;
    display:none;
}
.infoAlergias2 {
    margin-bottom: auto;
    position:relative;
    display: flex;
    flex-direction: column;
    display:none;
}

@media (max-width: 1440px) {

    .uno {
        flex-direction: column;
    }

    .der {
        margin-top: auto;
        margin-bottom: auto;
    }

    .infoSalud {
        display: flex;
        flex-direction: column;
        position:relative;
        background-color: 'red';
        border-radius: 0.5rem;
        margin: 1rem;
        height: 25rem;
        padding: 1rem;
        min-width: 40rem;
        display:none;
    }
    .infoSalud2 {
        display: flex;
        flex-direction: column;
        position:relative;
        background-color: 'red';
        border-radius: 0.5rem;
        margin: 1rem;
        height: 25rem;
        padding: 1rem;
        min-width: 40rem;
    }

    .infoAlergias { 
        display:none;
    }
    .infoAlergias2 {
        margin-bottom: auto;
        position:relative;
        display: flex;
        flex-direction: column;
    }

    .infoPatologia {
        display: flex;
        flex-direction: column;
        background-color: 'black';
        border-radius: 0.5rem;
        margin: 1rem;
        padding: 1rem;
        top: 25rem;
        
    }

    .infoTratamientosActivos {
        display: flex;
        flex-direction: column;
        position:relative;
        border-radius: 0.5rem;
        margin: 1rem;
        padding: 1rem;
        margin-top: 9rem;
        height: auto;
    }

    .infoTratamientosNoActivos {
        display: flex;
        flex-direction: column;
        position:relative;
        border-radius: 0.5rem;
        margin: 1rem;
        padding: 1rem;
        height: auto;
    }

}
@import '~antd/dist/antd.css';
@import 'css/flags.css';
@import 'variables.scss';
@import 'common.scss';

.logo {
    font-weight: bold;
    font-size: 1.75rem;
    text-align: center;
    margin: 0.75rem;
    background-color: gray;
    margin-bottom: 1rem;
    line-height: normal;
    border-radius: 4px;
}

.logo--no-header {
    width: 80px;
    background-color: white;
    padding: 0.25rem;
    border-radius: 2px;
    margin-right: 1rem;
}

.table-button-bar {
    margin-bottom: 1rem;
    
}

.login-container {
    background: url('./images/login-bg.jpg') no-repeat top center;
    height: 100%;
    width: 100%;
    position: fixed;
    background-size: cover;

    .ant-card-head {
        font-size: 1.5rem;
        text-align: center;
        color: white;
        background-color: $header-bg-color;
    }

    .login__form {
        margin: 0 auto;
        margin-top: 8rem;
        border: 4px solid $header-bg-color;
        width: 32rem;
        border-radius: 1rem;
    }

    .login-container--mask {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: white;
        opacity: 0.25;
    }
}

.header-app {
    
    height: 64px; 
    padding-left: 2rem;
    padding-right: 2rem;

    background-color: white;

    box-shadow: 0 2px 4px darkgray;
    z-index: 1000;

    .header-logo {
    
        .header-logo--inner {
            width: 176px;
            img {
                width: 100%;
            }
        }
    }

    .header-options--container {

        color: $dark-color;
    
        .header-options--option + .header-options--option {
            margin-left: 2rem;
        }
    
        .ant-badge-count {
            box-shadow: none;
        }
        &.otp{
            font-size: 0.85rem;
            margin-right: 2rem;
            height: 40px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            color: $dark-color;
            padding-bottom: 0.25rem;
            svg{
                font-size: 1.5rem !important;
            }

            &::after{
                background-color: #002E50;
                content: '';
                width: 1px;
                height: 69px;
                display: inline-block;
                margin-left: 1rem;
            }
            
        }
    
        [class*="fa"] {
            cursor: pointer;
            font-size: 1.5rem;
        }
    }

    .suscription{
        display: flex;
        align-items: center;
        margin-right: 1rem;

        line-height: normal;

        .suscription--inner{
            text-align: right;
            color: $dark-color;
            font-size: 0.85rem;
            margin-right: 2.25rem;

            .detalle{
                font-weight: bold;
            }
            .renovar{
                font-weight: bold;
                span{
                    margin-right: 0.2rem;
                }
                color: rgb(255, 213, 0);
            }
            button {
                padding: 0;
                height: auto;
                line-height: 0;
            }
        }

        &::after {
            content: '';
            width: 4px;
            background-color: $dark-color;
            height: 40px;
            border-radius: 2px;
            display: inline-block;
        }

    }
    .prestador {

        display: flex;
        align-items: center;
        margin-right: 1rem;

        line-height: normal;

        .prestador--inner {
            text-align: right;
            color: $dark-color;
            font-size: 0.85rem;
            margin-right: 2.25rem;
            button {
                padding: 0;
                height: auto;
                line-height: 0;
            }
        }
        
        &::after {
            content: '';
            width: 4px;
            background-color: $dark-color;
            height: 40px;
            border-radius: 2px;
            display: inline-block;
        }
    }

    .headerext-otpconfig{

        font-size: 0.85rem;
        margin-right: 1rem;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        color: $dark-color;

        svg {
            font-size: 1.5rem;
        }

    }

    .info-bloqueo {
        font-size: 0.85rem;
        height: 40px;
        border-radius: 20px;
        margin-right: 0 !important;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        cursor: pointer;
        font-weight: bold;

        svg {
            font-size: 1.5rem;
        }

        .ant-badge {
            color: $dark-color-gray;

            [title='0'] {
                background-color: gainsboro;
                color: $dark-color-gray;
            }
        }

        &.active {

            .ant-badge {
                color: $dark-color !important;
            }

            &:not(.stop-animation) svg {
                animation: ring 4s .7s ease-in-out infinite;
                transform-origin: 50% 4px;
            }
        }

        .ant-badge-count {
            box-shadow: none;
        }

        @keyframes ring {
            0% { transform: rotate(0); }
            1% { transform: rotate(30deg); }
            3% { transform: rotate(-28deg); }
            5% { transform: rotate(34deg); }
            7% { transform: rotate(-32deg); }
            9% { transform: rotate(30deg); }
            11% { transform: rotate(-28deg); }
            13% { transform: rotate(26deg); }
            15% { transform: rotate(-24deg); }
            17% { transform: rotate(22deg); }
            19% { transform: rotate(-20deg); }
            21% { transform: rotate(18deg); }
            23% { transform: rotate(-16deg); }
            25% { transform: rotate(14deg); }
            27% { transform: rotate(-12deg); }
            29% { transform: rotate(10deg); }
            31% { transform: rotate(-8deg); }
            33% { transform: rotate(6deg); }
            35% { transform: rotate(-4deg); }
            37% { transform: rotate(2deg); }
            39% { transform: rotate(-1deg); }
            41% { transform: rotate(1deg); }
          
            43% { transform: rotate(0); }
            100% { transform: rotate(0); }
          }
    }
}

.content-app {

    .ant-layout-sider {

        &, .ant-menu {
            background-color: whitesmoke;
        }

        .ant-menu {
            margin-top: 4px;
            border-right-width: 0;
        }

        .ant-menu-item {
            height: 44px;
            line-height: 44px;
            padding-top: 4px;
            svg {
                font-size: 24px;
            }
        }
    }

    .content-app-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .language-selector {
            width: 100%;
            text-align: center;
            background-color: whitesmoke;

            .ant-select-selector {
                border: none;
                background-color: whitesmoke !important;
            }
        }
    }
}

.loading-img {
    background: url('./images/loading.gif') no-repeat center center;
    background-size: 36px 36px;
    display: inline-block;
    min-width: 2rem;
    min-height: 2rem;

    &.loading-img--small {
        min-width: 1rem;
        min-height: 1rem;
    }
}

.loading-img--inline {
    display: inline-block;

    img {
        max-height: 1.4rem; 
        margin-right: 0.5rem;
    }
}

.logo-img {
    max-height: 1.4rem;
    max-width: 10rem;

    &.logo-img--small {
        max-height: 1.25rem;
        max-width: 8rem;
    }
}

.table-container {
    position: relative;
}

.table-pagination {
    position: absolute;
    top: 7px;
    left: 8rem;
}

.paragraphs__container {
    padding: 0.5rem 1rem;
    background-color: #F4F5F7;
    border: 1px solid #C1C7D0;

    p {
        margin-bottom: 0;
    }
}

.button-icon-ellipsis {
    width: 100%;

    & > div {
        width: 100%;
        display: flex; 
        align-items: center;
        text-align: left;
    }

    .filename {
        width: calc(100% - 24px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.dispensacion-column {

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message_receta {
        font-size: 0.60rem;
        margin-left: 1.25rem;
    }
}

.dipensada_parcialmente_status {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: linear-gradient( -45deg, #A9A9A9, #A9A9A9 49%, white 49%, white 51%, #8EAD38 51% ); 
    border-radius: 50%;
}

.new_prescription_container {

    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;

    &.embed {

        .ant-collapse-header {
            background-color: gainsboro !important;

            .data_container {
                background-color: inherit;
            }
        }

        .title {
            display: none !important;
        }

        .collapseChildren {
            padding-top: 0.25rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            .data_container {
                padding-left: 0rem !important;
                padding-right: 0rem !important;
            }
        }
    }

    &.fav{
        max-width: none !important;
    }

    .prescription_buttons_container {
        display: flex;
        justify-content: space-between;
    }
    .activeprescription-collapse {
        &.ant-collapse > .ant-collapse-item{
            background-color: gainsboro !important;
        }
        .ant-collapse-content-box > .ant-col{
            background-color: gainsboro !important;
        }
        .ant-collapse-item > .ant-collapse-header{
            padding:0;
            width: fit-content;
            padding-right: 2.5rem !important;
            background-color: gainsboro !important;
            cursor: auto;
    
            & > span {
                top:0.75rem !important;
                padding:0!important;
                cursor: pointer !important;
            }
        }
       
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        padding:0;
        padding-right: 3rem;
        background-color: whitesmoke;
        cursor: auto;

        & > span {
            cursor: pointer;
        }
    }
    .ant-collapse-content > .ant-collapse-content-box{
        padding:0;
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
        padding: 0;
    }
    .ant-collapse > .ant-collapse-item{
        border-bottom: none;
    }
    .ant-collapse-content-box > .ant-col{
        background-color: whitesmoke;
    }

    .data_container {
        
        background-color: #F5F5F5;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        overflow: hidden;

        .title {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin-right: 0.5rem;
            flex-shrink: 0;
        }

        .paciente-title-container {

            display: flex;
            align-items: center;
            flex-wrap: wrap;
            min-height: 30px;

            .paciente-mutualista-container {
                position: relative;
                img {
                    height: 20px;
                }

                .circleFlag {
                    margin-left: 0;
                    margin-right: 8px;
                }
            }

        }

        &.collapseChildren{
            padding: 0rem 0rem 0rem 2.6rem;
            &.patient{
                padding-left:2.2rem;
            }
        }
        .colAlergiasList{
            margin-right: 1.5rem;
        }
        .title-value-container{
            text-align: left;
            font-size: larger;
            font-weight: 500;
            max-width: 450px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .title-nif-container{
            font-size: larger;
            font-style: italic;
            margin-left: 0.25rem;
        }
        .address-title{
            margin-left: 0.25rem;
            font-style: italic;
            font-size: larger;
        }
    }

    .number {
        display: inline-block;
        background-color: #002E50;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        color: #ffffff;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 600;
        margin-right: 1rem;
    }
    .aditionalPatientandNumberContainer{
        position: relative;
        margin-left: 0.5rem;

        .button_aditional_info{
            &.false{
                visibility: hidden;
            }
        }

        .numberAlergiesPatologies{
            display: inline-block;
            position: absolute;    
            top: -8px;
            right: -8px;
            background-color: red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            color: #ffffff;
            font-size: 0.6rem;
            text-align: center;
            font-weight: 600;
            &.false{
                visibility: hidden;
            }
        }

    }

    svg.number {
        color: #95AB4B;
        background-color: white;
    }

    .text_title {
        color: #002E50;
        font-size: 1.2rem;
        text-align: center;
        font-weight: 700;
    }

    .text_subtitle {
        margin-left: 1rem;
        font-size: 0.7rem;
        color: grey;
    }

    .link_title {
        font-size: 0.7rem;
        font-weight: 600;
        cursor: pointer;
    }

    .space_title {
        margin-left: 2rem;
    }
    
    .button__details.ant-btn,
    .button__details .ant-btn{
        font-size: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;

        &.icon-image {
            width: auto;
            padding: 0 2px;

            img {
                height: 16px;
            }
        }
    }

    .button__prescription {
        margin-left: 0.5rem;
        font-size: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
    }

    .nodata {
        font-size: 0.7rem;
        font-style: italic;
    }


}

.code_identification {
    margin-bottom: 0.5rem;
}

.tutores_title{
    font-weight: 600;
}

.tutor_container {
    background-color: #F5F5F5;
    width: 100%;
    padding: 0.25rem 0.5rem;

    .icon_tutor {

        .icon_button {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 10px;
        }
        .consentimiento_button {
            font-size: 12px;
        }
    }
}

.locations_title{
    font-weight: 600;
}

.location_container{
    background-color: #F5F5F5;
    height: 2rem;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    &.noData {
        font-style: italic;
    }
    
    .location__details {
        overflow:hidden; 
        white-space: nowrap; 
        text-overflow: ellipsis;
        margin-right: 0.5rem;
    }

    .button_selected {
        text-align: end;
        width: 100%;
        margin-top: 0rem;
    }

    .select__button {
        background-color: #ffffff;
        color: #7AB4F9;
        //border: 1 solid #7AB4F9;
        width: 100px;
    }
}


.selected__button {
    border-color: #95AB4B !important;
    background-color: #95AB4B;
    color: #ffffff;
    width: 100px;
}

.signContainer {

    text-align: center;
    margin-top: 2rem;

    .signPasswordSubTitle {
        text-align: left;
        margin: 1rem 0;
    }
}

.otpContainer{
    text-align: center !important;
    margin-top: 2rem;

    .ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0;
    }
    
    .ant-form-item-explain, .ant-form-item-extra {
        min-height: 0;
    }

    .signOtpTitle {
        font-size: 14px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .login_otp_text {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        width: 2.6rem;
        height: 2.6rem;
        font-size: 3rem;
        text-align: center;
        padding-top: 0rem;
        border-radius: 8px !important;
    }

    .warning_Message {
        text-align: left;
        width: 100%;
    }

    .error_Message {
        text-align: left;
        width: 100%;
    }

    .access__expired {
        margin-top: 1rem;

        .ant-alert-content {
            text-align: left;
        }
    }
}

.enlace_token_container {
    background-color: whitesmoke;
    height: 100%;
    display: flex;
    justify-content: center;

    &.prescriptorRegistration{
        height: fit-content;
        display: block;
    }

    &.subscription{
        height: fit-content;
    }

    .enlace_token_card_container {
        width: auto !important;
        justify-content: center;

        .enlace_token_card {
            margin-top: 2rem;
            margin-bottom: 2rem;
            background-color: white;
            max-width: 600px;
            padding: 2rem;
            border-radius: 4px;
            box-shadow: 0px 2px 8px gainsboro;

            &.subscription{
                margin-bottom: 4rem;
                max-width: none;
            }

            .enlace_token_logo {
                display: flex;
                justify-content: center;

                img {
                    max-width: 200px;
                }
            }
            
            .enlace_token_title {
                margin-top: 1.5rem;
                font-weight: bold;
                text-align: center;
                font-size: 1rem;
                &.suscripcion{
                    font-size: 1.12rem;
                }
            }
        
            .enlace_token_prestador {
                margin-top: 1rem;
                padding-bottom: 0;

                &.welcome {
                    text-align: left;
                }
            }
            .enlace_token_terms {
                text-align: left;
                font-size: 0.9rem;
                margin-top: 1rem;
                padding-bottom: 0;
                font-weight: normal;
                border: solid 1px gainsboro;
                border-radius: 5px;
                padding: 1rem;

                ul{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                }
                li:not(:last-child){
                    margin-bottom: 6px;
                }
            }
        
            .acept-consent {
                display: flex;
                justify-content: center;
                margin-top: 1rem;

                .acept-enlace_token_text {
                    margin-left: 0.5rem;
                }
            }
        
            .enlace_token_result {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;

                .enlace_token_result--inner {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                }
                
                .enlace_token_result-logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.5rem;

                    .not-valid-logo {
                        border-radius: 50%;
                        background-color: #f5222d;
                        padding: 6px;
                        width: 33px;
                        height: 33px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            font-size: 1.25rem;
                            color: white;
                        }
                    }
                }
            
                .enlace_token_result-text {
                    margin-top: 0.25rem;
                    margin-left: 1rem;
                }
            }

            .enlace_token_buttons {
                margin-top: 1rem;
                display: flex;
                justify-content: center;
            }

            .alta-buttons {
                margin-top: 1rem;
                display: flex;
                justify-content: center;
            }

            .subscription-buttons{
                margin-top: 1rem;
                display: flex;
                justify-content: center;
                Button:first-child{
                    margin-right: 12px;
                }
            }
            
            .ant-form + .alta-buttons {
                margin-top: 0rem;
            }

            .form_step2 {
                margin-top: 1rem;
            }
           

            .tipoClienteForm{
                margin-top: 30px;
                display: flex;
                .tipoDeClienteTextDiv{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 12px;
                    margin-bottom: 24px;
                }
            }
            .lineOfPoints{
                border-bottom: 2px dotted black;
                margin-top: 5rem;
            }
            .moneyDiv{
                margin-top: 2rem;

                text-align: end;
                div:first-child{
                    font-size: 1.9rem;
                    font-weight: 750;
                }
                div:last-child{
                    margin-left: 0.1rem;
                    font-size: 0.8rem;
                    font-style: italic;
                }
            }
            .datosDeFacturacionTitle{
                font-weight: bold;
                font-size: 0.95rem;
                margin: 1rem 0rem 1rem 0rem;
                .tipoPago{
                    font-weight: normal;
                }
                &.textoSuscripcion{
                    font-weight: normal;
                }
            }
        }
    }
}

.form-alta-prescriptor {
    justify-content: center;

    .ant-input.ant-input-lg.ant-input-disabled {
        color: rgba(0, 0, 0, 0.85);
        font-weight: normal;
        font-size: 14px;
    }
}

.credential_condition {
    margin-top: 1rem;
}

.form__password {
    margin-top: 1rem;
}

.prescription-status {

    &.pending {
        background-color: $icon-color;
    }
    &.emitted {
        background-color: #95AB4B;
    }
    &.cancelled {
        background-color: #f5222d;
    }
    
 
    color: white;
    margin-top: 0.1rem;
    padding: 0px 6px;
    font-size: 0.75rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 20px;
    margin-right: 0.5rem;
}
.buttons-prescriptionfav{
    display: flex;
    position: absolute;
    right: 0;
    top:0;
}
.buttons-prescriptionfavselect{
    display: flex;
    position: absolute;
    right: 0;
    top:40%;
}

@mixin info-icon {
    color: $icon-color;
    margin-left: 0.25rem;
}

.medication-card__container {
    background-color: whitesmoke;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 0.5rem;

    .medication-card-info {
        display: flex;
        padding: 1rem;

        & + .alertsContainer.posology {
            margin-top: 0;
        }

        .medication-photo {
            display: inline-block;
            flex-shrink: 0;

            & > div {
                overflow: hidden;
                border-radius: 8px;

                width: 160px;
                height: 100px;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100px;
            }
        }

        .medication-photo-placeholder {
            display: inline-block;
            flex-shrink: 0;

            width: 160px;
            height: 100px;

            background-color: gainsboro;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 3.5rem;
                color: lightgray;
            }
        }

        .medication-photo + .medication-info,
        .medication-photo-placeholder + .medication-info {
            margin-left: 1rem;
        }

        .medication-info {
            .medicine-info-newprescription-icon {
                @include info-icon;
                cursor: pointer;
            }

            .medication-type--container {
                display: flex;
                flex-wrap: wrap;
                & > * {
                    flex-shrink: 0;
                }

                .medication-type {
                    margin-top: 0.1rem;
                    background-color: #002E50;
                    color: white;
                    padding: 0px 6px;
                    font-size: 0.65rem;
                    border-radius: 4px;
                    font-weight: bold;
                    display: inline-flex;
                    align-items: center;
                    margin-right: 0.5rem;
                    height: 20px;
                    line-height: 12px;
                    .code-medication {
                        margin-left: 0.5rem;
                        padding: 0 0.33rem;
                        border-radius: 2px;
                        background-color: white;
                        color: black;
                    }
                }
                .medication-prestador-info {
                    background-color: #DCDCDC;
                    color: black;
                    padding: 4px 4px 0 4px;
                    margin-left: 6px;
                    font-size: 0.65rem;
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                }
                .prescripcion-id {
                    margin-left: 0.5rem;
                    color: lightslategray;
                }

            }

            .medication-name {
                padding: 0.25rem 0;
                font-size: 1.1rem;
                font-weight: bold;
                color: $dark-color;

                div {
                    display: inline-block;
                    font-weight: normal;
                }

                .icon {
                    margin-left: 0.5rem;
                    color: $icon-color;
                    cursor: pointer;
                }
            }
            .medication-cn {
                color: gray;
                font-style: oblique;
            }
        }
    }
}
.receipt-blocked-container {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #f5222d;
    font-size: 0.8rem;
    background-color: #fef0f1;
    display: flex;

    .icon {
        color: #f5222d;
        font-size: 2rem;
        margin-right: 1rem;
    }
}

.receipt-container {
    margin-top: 1rem;

    .adherencia__container--outer {

        display: inline-flex;
        align-items: center;
        line-height: normal;

        .adherencia__container {

            padding: 2px 0 2px 6px;
            margin-left: 8px;
            color: white;
            background-color: dimgray;
            border-radius: 4px;
            overflow: hidden;
            font-size: 0.75rem;

            .adherencia__valor {
                background-color: darkgray;
            }

            &.baja {

                .adherencia__valor {
                    background-color: #f5222d;
                }
            }

            &.media {

                .adherencia__valor {
                    background-color: rgb(247, 141, 21);
                }
            }

            &.alta {

                .adherencia__valor {
                    background-color:#95AB4B;
                }
            }

            .adherencia__valor {
                margin-left: 8px;
                color: white;
                font-weight: bold;
                padding: 2px 4px;
                border-radius: 0 4px 4px 0;
            }
        }

        .fa-exclamation-circle {
            @include info-icon;
            margin-left: 0.5rem;
        }
    }

    .receipt-card {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        border-radius: 4px;
        font-size: 0.8rem;
        width: 60%;
        background-color: white;

        .dispensed-dates {
            color: gray;
            font-style: oblique;
        }

        .transacciones {
            padding-left: 1rem;

            .replaced {
                color: $icon-color;
            }

            .blocked {
                color: #f5222d;
            }
        }
    }
}

.data_container .medication-card-info {
    padding: 0;
}

.medication-packages__container {

    border: 1px solid gainsboro;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 1rem;
    color: grey;

    .medication-packages-button {
        color: $icon-color;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 0.5rem;
    }

    .medication-packages-info {
        font-style: oblique;
    }
}

.sign__container {
    height: 100%;
    width: 100%;
    justify-content: center;

    .acept-sign {
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        .enlace_text {
            margin-left: 0.5rem;
        }
    }

    .sign_buttons {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }
}

.prescription-confirmation {
    margin-top: 1rem;

    .prescription-confirmation-logo {
        display: flex;
        justify-content: center;
    }

    .prescription-confirmation-text {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

    }

    .prescription-confirmation-button {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
}

.home_page__container {
    .no_data_container {
        display: inline-block;
        margin-top: 1rem;
        background-color: gainsboro;
        color: white;
        font-weight: bolder;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
    
        .no_data {
            display: flex;
            align-items: center;
            .icon {
                font-size: 2.5rem;
                margin-right: 1rem;
            }
        }
        
    }
    
    .dispensations-page {
        display: flex;

        .pagination {
            position: absolute;
            right: 1.5rem;
        }
    }

    .dispensation_container {
        display: inline-block;
        margin-top: 1rem;
        background-color: whitesmoke;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        width: 100%;
    
        .dispensation-date {
            display: flex;
            align-items: top;
    
            .button__details {
                margin-left: 0.5rem;
                font-size: 0.5rem;
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    
        .substitution {
            color: $icon-color;
        }
        
    }
}
.newSign_container {
    padding-top: 2rem;

    .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .sign-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;
        padding: 1rem;
        background-color: whitesmoke;
        border-radius: 4px;

        & > * + * {
            margin-top: 1.5rem;
        }
    }

    .waiting-text {
        color: gray;
        font-style: oblique;
    }
}

.capa2{
    padding: 2rem;
}
.tituloCapa2{
    text-decoration: underline;
}
.textoCapa2{
    font-size: large;
}
.tablaCapa2{
    border: 1px solid black;
    td{
        text-align: center;
        padding: 1rem;
        border: 1px solid black;
    }
    th{
        border: 1px solid black;
        text-align: center;
        padding: 1rem;
    }
}

.no-arrow {
    .ant-popover-arrow {
        display: none;
    }
}

.otp-option-container {
    border: 1px solid #c1c1c1dc;
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 10px;

    .otp-option-title {
        font-size: 10px;
        columns: #c1c1c1;
    }
}

.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    text-align: justify;

    img {
        margin: 30px 0;
    }

    .qr-input-code {
        width: 100%;
        display: flex;

        .code-input {
            flex: 1 1 auto;

            input {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
        button {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    .qr-stores-mensaje{
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .qr-stores-container{
        display: flex;
    
        .qr-store{
            display: flex;
            flex-direction: column;
            align-items: center;
            &.android{
                .qr-store-icon-image{
                    height: 27px;
                    margin-top: 3px !important;
                    margin-bottom: 3px !important;
                }
                margin-right: 6rem;
            }

            .qr-store-icon-image{
                height: 35px;
                margin: 0 !important;
            }

            .qr-store-image{
                width: 100px;
                height: 100px;
                margin: 0 !important;

            }
        }


    }
}

.aditionalPatientInfoForm{
    .alturaPesoDiv{
        padding-top: 1rem;
    }

}
.addAlergiesDiv{
    color: grey;
    align-items: center;
    display: flex;
    font-size: large;
    font-weight: bold;
    Button {
        margin-left: 0.5rem;
    }
}
.emptyAlergiesTable{
    background-color: whitesmoke;
    color: grey;
    margin: 1.5rem 0 1.5rem 0;
    margin-left: 0;
    font-style: oblique;
    text-align: left;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.5rem;

}
.alergiesSearch{
    padding-top: 0.5rem;
    .alergiesSearchTitle{
        margin: 0.5rem;
        font-weight: bold;
        margin-left: 0;
        color: grey;
    }
}
.aditionalInfoModal{
    .ant-modal-close {display: none;}
}
.newText {
    color: green;
    font-weight: bold;
}
.checkedToDeleteText{
    color: red;
    font-weight: bold;
}
.tableContainer{
    margin: 0.5rem;
    margin-bottom: 1.5rem;
    margin-left: 0;
    Table {
        border-spacing: 0 0.8rem; 
        border-color: black;
        .ant-table-thead{
            display: none;
        }
       
    }
    
}

.tableRow {

    td:first-child{
        border-left: solid 1px lightgrey  !important;
        font-weight: bold;
    }
    td{
        border-top: solid 1px lightgrey  !important;
       
        border-bottom: solid 1px lightgrey  !important;
    }
    td:last-child{
        border-right: solid 1px lightgrey  !important;
       
        
    }
}

.table-pagination-patology {
    position: absolute;
    top: 7px;
    left: 13rem;
}
.table-pagination-alergy {
    position: absolute;
    top: 7px;
    left: 12rem;
}

.menu-vidal-option {

    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;

    img {
        min-width: auto !important;
        border: 1px solid gray;
        border-radius: 2px;
    }
}

.modal-vidal-vmp {

    .ant-modal-body {
        height: calc(100vh - 6rem);

        .modal-vidal-vmp-iframe-wrapper {
            margin-top: 2rem;
            height: 100%;
            overflow: hidden;
        }

        iframe {
            transform: translateY(-8px) translateX(-8px);
            width: 100%;
            height: calc(100% - 2rem);
        }
    }
}
.optionalText{
    margin-left: 0.2rem;
    color: grey;
    font-size: small;
    font-weight: bold;
}
.button_aditional_info_container{

    position: relative;
    .button_aditional_info{
        position: absolute;
        &.false{
            visibility: hidden;
        }
    }
}

.alerts{
    background-color: #F5F5F5;
    padding: 0.5rem 1rem;
    border: 3px whitesmoke solid;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    padding: 0.8rem;
    &.noData{
        display: flex;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: grey;
        border:none;
    }
    &.ICON{
        padding: 1rem;
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        border:none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        width: fit-content;
        background-color: grayscale($color: #9d9d9d);
        color: lightgray;
    }
    
    &.LEVEL_4{
        &.ICON{
            padding: 1rem;
            color: white;
            background-color: #CF113C;
        }
        padding: 0.4rem 0.4rem 0.4rem 0.8rem;
        border-color: #CF113C;
    }
    &.LEVEL_3{
        &.ICON{
            padding: 1rem;
            color: white;
            background-color: #FF4326;
        }
        padding: 0.4rem 0.4rem 0.4rem 0.8rem;
        border-color: #FF4326;
    }
    &.LEVEL_2{
        &.ICON{
            padding: 1rem;
            color: white;
            background-color: #F97D00;
        }
        padding: 0.4rem 0.4rem 0.4rem 0.8rem;
        border-color: #F97D00;
    }
    &.LEVEL_1{
        &.ICON{
            padding: 1rem;
            color: white;
            background-color: #FFDC2C;
        }
        padding: 0.4rem 0.4rem 0.4rem 0.8rem;
        border-color: #FFDC2C;
    }
    &.INFO{
        &.ICON{
            padding: 1rem;
            color: white;
            background-color: #8EAD38;
        }
        padding: 0.4rem 0.4rem 0.4rem 0.8rem;
        border-color: #8EAD38;
    }
    
}

.unsecurizedRow {
    svg {
        margin-right: 0.5rem;
        color: $icon-color;

    }
    
    margin-top: 0.25rem;
    font-size: small;
    color: dimgray;
}

.button__addtable {
    font-size: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
}
.titleAlertas{
    font-weight: bold;
    margin-right: 1rem;
}
.button_vidal_alerts{
    margin-left: 0.5rem;
}
.alertsContainer{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    .button__details {
        font-size: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
    }
    .alerts{
        background-color: white;
    }
    &.posology{
        margin: 1rem;
    }
}
.gifLoading{
    width: 2rem;
    height: 2rem;
    font-size: 1REM;
}

.modal-informe-alertas{
    width:51rem !important;
    .ant-modal-body {
        height: calc(100vh - 8rem);

        .modal-informe-alertas-iframe-wrapper {
            margin-top: 2rem;
            height: calc(100% - 2rem);

        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
.iconCollapse{
    position: relative;
    font-size: 1rem;
    size: 1rem;
}
.ant-col{
    &.collapseChildren{
        padding: 0px 24px 12px 12px!important;
    }
}
.pacienteDataContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 70rem;
}
.selectPatientFormWithPasting{
    margin-bottom: 1rem;
    
}
.buscarPatientButton{
    width:100%;
    
}
.comprobarDataPastingButton{
    width: 100%;
    padding: 0;
    font-size: 12px;
}

.medicamento-vidal {

    display: flex;
    color: dimgray;

    div {
        display: flex;
        flex-direction: column;
    }

    width: 100%;
    flex-direction: column;

    .content-1 {
        background: gainsboro;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        flex-direction: column;
    }

    .content-2 {
        margin-top: 0.5rem;
        width: 100%;
        flex-direction: row;
        background-color: whitesmoke;
    }

    .content-1 + .content-2, .content-2 + .content-2 {
        margin-top: 0.5rem;
    }

    .medicamento-vidal-item {
        margin-top: 0.2rem;
        width: 100%;

        div {
            background-color: whitesmoke;
        }

        .header {
            color: white;
            background: darkgray;
            justify-content: center;
        }

        .header-left {
            color: white;
            background: darkgray;
            justify-content: left;
        }
        .header-light {
            background: lightgray;
            //justify-content: left;
        }

    }

    .medicamento-vidal-image-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 700px;
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .medicamento-vidal-image-container li {
        list-style: none;
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
        margin-left: 5px;
        margin-right: 5px;
        //border: 1px solid lightgray;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .medicamento-vidal-image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .medicamento-header-info {
        justify-content: flex-start;
        flex-direction: row;

    }


}
.selectMedicineDetalleVidalDiv{
    display: flex;
    align-items: center;
}

.medicamento-equivalente-item {

    div {
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    div:nth-child(odd) {
        background-color: #fff;
    }

    div:nth-child(even) {
        background-color: whitesmoke;
    }

    div:hover {
        font-weight: bold;
        background: #e2eff6;
    }

    max-height: 20vh;
    overflow-y: auto;
}

.medicamento-equivalente-header {
    display: flex;
    color: white;
    background: darkgray;
    justify-content: left;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-top: 1rem;
}

.medicamento-vidal-modal {
    top: 40px;
}

.ant-col{
    &.colTextoSuscripcion{
        padding-right: 6px !important;
    }
    &.colLineOfPoints{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &.colMoneyDiv{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.suscripcionDetalleDiv{
    div{
        margin-top: 1rem;
    }
}

.facturasTitle{
   
    margin-top: 1rem;
    font-weight: bold;
    
}

textarea.ant-input.floating {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.activeprescription-header{
    width: fit-content;
    color: $icon-color;
    padding: 0.5rem 0 0.5rem 1rem;
    font-weight: bold;
}
.psumInfoPopOver { 
    .ant-popover-inner {
       background-color: rgb(255, 234, 245);
    }
    .ant-popover-arrow{
        border-color:  rgb(255, 234, 245) !important;
    }
 }
 
.filter-badge{
    z-index: 1;
}
.anteriorOrFavDataList{
    max-height: 77vh;
    overflow: hidden auto;
}

.psumInfo{
    display: flex;
    flex-direction: column;
    max-width: 270px;
    .psumInfo-periodoTitle{
        color:deeppink;
        font-weight: bold;
        
    }
    .psumInfo-observacionesTitle{
        color:deeppink;
        font-weight: bold;
        
    }
    .psumInfo-periodo{
        color:deeppink;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
        margin-left:0.2rem;
    }
    .psumInfo-observaciones{
        color:deeppink;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
        margin-left:0.2rem;
    }
    Button{
        svg{
            font-size: 2.6rem !important;
            padding: 0.5rem;
            padding-left: 0;
        }
        height: fit-content;
        width: 270px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        div{
            white-space: normal;
            text-align: left;
            font-size: 0.8rem;
            
        }
    }
}

.centered-frame {

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    //background-image: url('images/background.png');
    background: linear-gradient(0deg, rgb(246, 246, 246) 0%, rgb(172, 172, 172) 100%);

    .new_prescription_container {

        width: 100%;

        .data_container {
            border-radius: 8px;
            border: 1px solid gray;
            box-shadow: -4px 4px 16px 0px darkgray;
        }
    }
}

.selectmedicine__icon__container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.boton__emitir--container {

    position: relative;
    .boton__emitir--exlamacion {

        position: absolute;
        top: -8px;
        left: -8px;

        border-radius: 4px;
        background-color: #ffd500;
        box-shadow: 1px 1px 1px gray;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            font-size: 0.75rem;
            position: relative;
            top: 1px;
            color: black;
        }
    }
}

.statusPrescriptionDiv{
    .visaStatus{
        font-size: 0.7rem;
    }
}
.visa-v{
    margin-right: 6px;
    color: black;
    font-weight: bolder;
    font-size: larger;
}
.visa-status{
            
    &.pending{
        color: black;
        background-color: #fadb15f5;
    }
    &.authorized{
        background-color: #8EAD38;
    }
    &.denied{
        background-color: #FF4D4F;
    }

    color: white;
    margin-top: 0.1rem;
    padding: 0px 6px;
    font-size: 0.75rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 20px;
    margin-right: 0.5rem;
}
.radio-group-posology {
    margin-bottom: 1rem;
    margin-left: 0.25rem;
}
.boton__emitir--tooltip {

    .ant-tooltip-arrow-content, .ant-tooltip-inner  {
        background-color: #fff0a7 !important;
    }

    .ant-tooltip-content {
        width: 600px;
    }

    .ant-tooltip-inner {
        color: black;
        padding: 0.5rem 1rem;
    }

    .title {
        font-weight: bold;
    }

    ul {
        padding-left: 1.5rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}
@import '../../../variables.scss';

body.ext {
    background-color: #f4f4f4;

    .ant-btn-icon-only {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }

    .ext-layout {

        max-width: 1200px;
        margin: 0 auto;
        min-height: 100vh;
        overflow: hidden;

        .header-app {
            background-color: transparent;
            box-shadow: none;
        }

        .new_prescription_container {
            max-width: none !important;
        }

        .filters-drawer .ant-drawer-body {
            border-left: none;
        }

        .session-closed {
            width: calc(100% - 4rem);
            min-height: calc(100vh - 4rem);
            margin: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 48px;
            text-align: center;
            padding: 0 8rem;
            border-radius: 1rem;
            background-color: #002E50;
            color: white;
        }
    }

    .ext-header {
        
        display: flex;
        background-color: transparent !important;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }

        .logos-container {
            display: flex;
            flex-wrap: nowrap;
        }

        .logo-cosalud img, .logo-compania img {
            height: 28px;
        }

        .logo-compania {
            margin-left: 2rem;
        }

        .prestador {

            &::after {
                content: '';
                width: 1px;
                height: 64px;
                display: inline-block;
            }
        }

        .info-bloqueo {
            
            margin-right: 1rem;
            
            &::after {
                background-color: #002E50;
                content: '';
                width: 1px;
                height: 64px;
                display: inline-block;
                margin-left: 2rem;
            }
        }

        .header-options--option {
            font-size: 2rem !important;
        }
    }

    .ext-titulo {
        display: flex;
        align-items: center;
        padding: 0.5rem 2rem;
        background-color: $dark-color;
        color: white;
        
        height: 32px;

        &.noHeader {
            padding: 0.5rem 3.25rem 0.5rem 3.25rem;
        }

        span {
            line-height: 32px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:not(.active) {
                cursor: pointer;
            }

            &.active {
                flex-shrink: 0;
            }
        }

        svg {
            font-size: 1rem;
            margin: 0 1rem;
            
            &.close-icon {
                cursor: pointer;
                margin-left: 0.5rem;
                margin-right: 0;
            }
        }
    }

    .ext-content {
        position: relative;
        padding: 1.5rem 2rem;

        height: calc(100vh - 96px);
    }
}
@import '../variables.scss';

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #DFDFDF inset !important;
    -webkit-box-shadow: 0 0 0 30px #DFDFDF inset !important;
}

.home-container {
    
    height: 100%;
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    //background: url('../images/background.jpg') no-repeat;
    //background: url('../images/fondoLogin2.png') no-repeat;

    background-color: #9AC3EA;
    background-size: cover;

    .promo__mask {
        position: relative;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
    }

    .header {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 48px;

        background-color: rgba(255, 255, 255, 0.462);

        /*padding: 0 32px;*/

        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 120px;
            padding-left: 1rem;
        }

        flex-grow: 0;

        .button-bar {
            button:not(.ant-btn-primary) {
                border: none;
                color: black;
                font-weight: bold;
                font-size: 1rem;
                &.danger{
                    color:rgb(221, 40, 79);
                }
            }
            
        }
    }

    .body {
        width: 100%;
        overflow: auto;
        background: url('../images/fondoLogin2.png') no-repeat;
        background-size: cover;
        flex-grow: 1;
    }

    .logo-container {
        position: absolute;
        left: 1rem;
        bottom: 1rem;

        font-size: 0.75rem;
        font-weight: bold;
        color: black;
        
        img {
            &:first-child {
                margin-right: 1.5rem;
            }
            position: relative;
            
            top: 0px;
            max-width: 80px;
        }
    }

    .promo {
        //background: url('../images/doctor.png') no-repeat;
        background-position: 10% bottom;
        background-size: auto 95%;
        height: 100%;
        position: absolute;
        width: 100%;

        padding-top: 200px;

        .promo-right__container  {
            position: absolute;
            right: 140px;
            width: 700px;

            display: flex;
            align-items: center;
            flex-direction: column;


            .main {

                width: 100%;
                position: relative;
    
                color: white;
                background-color: rgba(0, 0, 0, 0.25);
                padding: 32px;
                font-size: 4rem;

                span {
                    font-weight: bold;

                    &.highlight {
                        color: #45C9F5;
                    }
                }
            }

            .message {

                padding: 8px;
                width: 100%;
                margin-top: 2rem;
                text-align: justify;
                color: white;
                font-size: 1rem;
            }
        }
    }
    .indexTextDiv{
        padding-top: 2.5rem;
        position:relative;
        margin-right: 2rem;
        text-align: end;

        .indexTextDiv-firstText{
            color: white;
            font-size: 1.8rem;
        }
        .indexTextDiv-secondText{
            color:black;
            margin-top: 1rem;
            font-size: 2.2rem;
            font-weight: bold;
        }
        .indexTextDiv-thirdText{
            align-items: start;
            text-align: left;
            margin-top: 1rem;
            margin-left: 38%;
            .innerThirdText{
                width: 62%;
                color:white;
                position: relative;
                
                font-size: 1.2rem;
                text-align: left !important;
            }
            
            

        }
    }
    .indexImagesButtonsDiv{
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        width: 100%;
        .imagesRow{
            margin-top: 2rem;
            margin-right: 2rem;
            margin-top: 4rem;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            img{
                height: 13rem;
                margin-left: 2rem;
            }
            :first-child{
                height: 10rem !important;
            }
            
        }
        .testUserButton{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            Button{
                width: 25rem;
                margin-top: 1.5rem;
                margin-right: 0.7rem;
                border-color: black !important;
                background-color: black;
                color: white;
                font-weight: bold;
                border-radius: 10px;
   
                height: fit-content;
                font-size: 1.2rem;
                svg{
                    margin-left: 15%;
                }
                &.altaPrescriptor{
                    svg{
                        margin-left: 7% !important;
                    }
                }
            }
        }

        .accessUserButton{
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;

            Button{
                width: 25rem;
                margin: 1.5rem 0.7rem 1rem 0rem;
                border-radius: 10px;
                border-color: white !important;
                background-color: white;
                color: black;
                font-weight: bold;
                height: fit-content;
                font-size: 1.2rem;
                svg{
                    margin-left: 11%;
                }
            }
        }
        
    }
}

.login-form--logo {
    max-width: 192px;
    margin-bottom: 32px;

}
.login__form {

        width: 100%;
        height: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 32px;
        align-items: center;
        transition: transform 0.5s ease-in-out;
        background-size: 600px 100%;
        border-radius: 15px !important;

        .otpContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-top: 0 !important;

            .ant-form-inline .ant-form-item-with-help {
                margin-bottom: 0;
            }
            
            .ant-form-item-explain, .ant-form-item-extra {
                min-height: 0;
            }
        }

        .warning_Message {
            text-align: left;
            width: 100%;
        }
    
        .error_Message {
            text-align: left;
            width: 100%;
        }

        .login_otp_text {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            width: 2.6rem;
            height: 2.6rem;
            font-size: 3rem;
            text-align: center;
            padding-top: 0rem;
            border-radius: 8px !important;
        }

        form {
            width: 100%;
            max-width: 450px;
            justify-content: center;

            .ant-input-affix-wrapper {
                border-radius: 18px !important;
            }

            .ant-input-affix-wrapper, input {
                background-color: #DFDFDF !important;
                color: #323947 !important;
                border: 0;
            }

            input {
                border-radius: 0 !important;
            }

            button:not(.ant-btn-link):not(.ant-btn-dashed) {
                background-color: $main-color;
                border-radius: 18px !important;
                border: 0 !important;
            }

            .button-otp-reenvio {
                border: 1px solid #1890ff !important;
                background: transparent;
                border-radius: 18px !important;
            }

            .button-otp-reenvio-link {
                color: #1890ff;
                background: transparent;
                border-radius: 4px;
            }

            .ant-form-horizontal .ant-form-item {
                margin: 0 8px;
            }

        }

        .fa-times {
            position: absolute;
            left: 80px;
            top: 12px;
            color: gray;
            font-size: 2rem;
            cursor: pointer;
        }

        .login-form--logo {
            max-width: 192px;
            margin-bottom: 32px;
        }

        .regenerate-password {
            font-weight: bold;
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
}   

.modalLogin{
    .ant-modal-content {
        border-radius: 20px;
        background-color: #f3f2f2;
      }
}

.vidalVademecumModal{
    .vidalVademecumModal-Image{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        img{
            max-width: 60px;
        }
        
    }
    div{
        display: flex;
        text-align: left;
        flex-wrap: nowrap;
        font-size: 0.8rem;
        flex-direction: column;
        &.vidalVademecumModal-textMarginBottom{
            margin-bottom: 1rem;
        }
        &.vidalVademecumModal-textMarginTop{
            margin-top: 1rem;
        }
        &.vidalVademecumModal-textConColor{
            display: block !important;
        }
    }
    .vidalVademecumModal-textLink{
        color: #cb1135;
        font-weight: bold;
        cursor: pointer;
    }

}

.altaPrescriptorForm{
    &.subtitulo{
        font-size: 1.25rem;
        color: #1890ff;
        font-weight: bold;
        margin-bottom: 1rem;

    }

    &.datosPersonales{
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
    }

    &.domicilioProfesional{
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }
}
.altaPrescriptorUpload{
    .ant-upload{

        display: flex;
        width: 100%;

    }
}

@media (max-width: 1300px) {

    .promo-right__container {
        width: 500px !important;

        .main {
            font-size: 2.5rem !important;
        }
    }
}

@media (max-width: 1100px) {

    .promo-right__container {
        width: 375px !important;

        .main {
            font-size: 1.75rem !important;
        }
        
        .message {
            background-color: rgba(0, 0, 0, 0.25);
            padding: 32px !important;
            font-size: 0.85rem !important;
        }
    }
    /*.indexTextDiv-firstText{

        text-align: center;

    }
    .indexTextDiv-secondText{

        text-align: center;

    }
    .indexImagesButtonsDiv .testUserButton{
        justify-content: center !important;
    }
    .indexImagesButtonsDiv .accessUserButton{
        justify-content: center !important;
    }
    .imagesRow{
        justify-content: center !important;
    }
    .indexTextDiv-thirdText{
        margin-left: 1rem !important;
        
    }*/


}

@media (max-width: 500px){
    .imagesRow{
        size: 1rem;
        margin-top: 2rem !important;
    }
    .home-container .header .button-bar {
        .ant-space-item{
            margin-right: 0 !important;
        }
        button{
        font-size: 0.58rem !important;
        }
    }
    .home-container .logo-container img {
        max-width: 60px !important;
    }
    .home-container .header .button-bar button{
        width: 3rem;
    }

    
    .home-container .indexTextDiv {
        .indexTextDiv-firstText{
            font-size: 1rem;
        }
        .indexTextDiv-secondText{
            font-size: 1.5rem;
        }

        .indexTextDiv-thirdText .innerThirdText{
            font-size: 0.9rem;
        }

    }
    .home-container .indexImagesButtonsDiv {
        .testUserButton Button {
            font-size: 1.0rem !important;
            width: 21rem !important;
        }
        .accessUserButton Button {
            font-size: 1.0rem !important;
            width: 21rem !important;
        }
        .imagesRow{
            :first-child {
                height: 9rem !important;
            }
            img {
                height: 9rem !important;
                margin-left: 0 !important;
            }
        }
    
    }
      
}  

@media (max-width: 920px) {

    .home-container .indexImagesButtonsDiv .imagesRow{
        img {
            margin-left: 0 !important;
        }
    }

    .home-container .indexImagesButtonsDiv {
        padding-right: 0 !important;
    }

    .home-container .logo-container{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 2rem;
        left: 0 !important;
    } 

    .promo-right__container {
        top: 275px;

        .message {
            font-size: 0.75rem;
        }
    }
    .home-container .indexTextDiv{
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }

    .indexTextDiv-firstText{

        text-align: center;

    }
    .indexTextDiv-secondText{

        text-align: center;

    }
    .indexTextDiv-thirdText{

        text-align: center;

    }
    .indexImagesButtonsDiv .testUserButton{
        margin-right: 0;
        align-items: center !important;
        justify-content: center !important;
        button{
            margin-right: 0 !important;
        }
    }
    .indexImagesButtonsDiv .accessUserButton{
        margin-right: 0;
        align-items: center !important;
        justify-content: center !important;
        button{
            margin-right: 0 !important;
        }
    }
    .imagesRow{

        margin-right: 0;
        justify-content: center !important;
    }
    .indexTextDiv-thirdText{
        margin-left: 1rem !important;
        
    }

}
@font-face {
    font-family: 'Catalana';
    src: url("../fonts/Catalana-Bold.woff2") format("woff2"), url("../fonts/Catalana-Bold.ttf") format("truetype"), url("../fonts/Catalana-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Catalana';
    src: url("../fonts/Catalana-Bold-Italic.woff2") format("woff2"), url("../fonts/Catalana-Bold-Italic.ttf") format("truetype"), url("../fonts/Catalana-Bold-Italic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Catalana';
    src: url("../fonts/Catalana-Regular.woff2") format("woff2"), url("../fonts/Catalana-Regular.ttf") format("truetype"), url("../fonts/Catalana-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Catalana';
    src: url("../fonts/Catalana-Regular-Italic.woff2") format("woff2"), url("../fonts/Catalana-Regular-Italic.ttf") format("truetype"), url("../fonts/Catalana-Regular-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'CatalanaSans';
    src: url("../fonts/CatalanaSans-Bold.woff2") format("woff2"), url("../fonts/CatalanaSans-Bold.ttf") format("truetype"), url("../fonts/CatalanaSans-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'CatalanaSans';
    src: url("../fonts/CatalanaSans-Regular.woff2") format("woff2"), url("../fonts/CatalanaSans-Regular.ttf") format("truetype"), url("../fonts/CatalanaSans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body.gco {
    color: #646464;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f4f4;
    font-family: 'CatalanaSans';

    .ant-btn {
        line-height: 2;

        &.ant-btn-primary:not([disabled]) {
            background: #dc0028;

            &.ant-btn-dangerous {
                background-color: #5d5d5d;
                border-color: #5d5d5d;
            }
        }

        &:not(.ant-btn-primary):not([disabled]):not(.ant-btn-icon-only) {
            svg {
                color: #dc0028;
            }

            &:hover span {
                color: #dc0028;
            }
        }

        &:not(.ant-btn-dangerous):not(.ant-btn-link):not([disabled]) {
            border: 1px solid #dc0028;

            &:not(.ant-btn-primary):not([disabled]) .anticon {
                color: #dc0028;
            }
        }
    }

    .ant-btn-icon-only {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }

    .ant-table {
        color: #646464 !important;
    }

    .gco-layout {

        max-width: 1200px;
        margin: 0 auto;
        min-height: 100vh;
        overflow: hidden;

        .header-app {
            background-color: transparent;
            box-shadow: none;
        }

        .new_prescription_container {
            max-width: none !important;
        }

        .filters-drawer .ant-drawer-body {
            border-left: none;
        }

        .session-closed {
            width: calc(100% - 4rem);
            min-height: calc(100vh - 4rem);
            margin: 2rem;
            background-color: #dc0028;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 48px;
            text-align: center;
            padding: 0 8rem;
            border-radius: 1rem;
        }
    }

    .gco-header {
        
        display: flex;
        background-color: transparent !important;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }

        .logos-container {
            display: flex;
            flex-wrap: nowrap;
        }

        .logo-cosalud img, .logo-compania img {
            height: 28px;
        }

        .logo-compania {
            margin-left: 2rem;
        }

        .prestador {

            .prestador--inner {
                color: #5d5d5d;
        
                b {
                    color:#dc0028;
                }
            }

            &::after {
                content: '';
                width: 1px;
                background-color: gainsboro;
                height: 64px;
                display: inline-block;
            }
        }

        .info-bloqueo {
            
            margin-right: 1rem;
            
            &::after {
                content: '';
                width: 1px;
                background-color: gainsboro;
                height: 64px;
                display: inline-block;
                margin-left: 2rem;
            }
        }

        .header-options--option {
            color: #dc0028;
            font-size: 2rem !important;
        }
    }

    .gco-titulo {
        display: flex;
        align-items: center;
        background-color: #5d5d5d;
        padding: 0.5rem 2rem;
        color: white;
        
        height: 32px;

        span {
            line-height: 32px;
            display: flex;
            align-items: center;

            &:not(.active) {
                color: gainsboro;
                cursor: pointer;
            }
        }

        svg {
            font-size: 1rem;
            margin: 0 1rem;
            
            &.close-icon {
                color: gainsboro;
                cursor: pointer;
                margin-left: 0.5rem;

                &:hover {
                    color: white;
                }
            }
        }
    }

    .gco-content {
        position: relative;
        padding: 1.5rem 2rem;

        height: calc(100vh - 96px);

        .prescription-status.emitted {
            background-color: #dc0028;
        }
    }

    .data_container :not(span) > .fa-check-circle {
        color: #dc0028 !important;
    }
}